.page {
  background-color: rgb(242, 248, 255);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px 43px 0px 43px;
}
.itemBox {
  display: flex;
}
.itemBox span {
  margin-top: 5px;
  margin-right: 4px;
  color: red;
}
.formLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}
.formTitle {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 5px;
}
/* .phoneInputBox {
  position: relative;
}
.phoneBox span {
  position: absolute;
} */

.phonexing {
  position: absolute;
  top: 335px;
  color: red;
}
.login {
  font-size: 14px;
  text-align: right;
  color: #1890ff;
  cursor: pointer;
}
.register {
  background-color: #006fff;
  text-align: center;
  padding: 5px 0;
  color: #fff;
  letter-spacing: 2px;
  margin-top: -25px;
}
.loginRegister {
  margin-top: 15px;
}
.loginType {
  display: flex;
  padding: 0 40px;
  justify-content: space-between;
  margin-bottom: 25px;
}
.loginBase {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
}

.loginOptionsSelected {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #006fff;
  padding-bottom: 6px;
  border-bottom: 2px solid #006fff;
  cursor: pointer;
}
