.menuItem {
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}

.menuItem:hover {
  border-bottom: 4px solid #fff !important;
}

.menuItemBox {
  text-align: center;
  min-width: 90px;
}



.log {
  width: 100%;
  background-color: red;
}

.headerRight {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  /* justify-content: right; */
}

.logo {
  height: 50px;
  width: 50px;
  margin-right: 33px;
}

.logoImg {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.message {
  height: 36px;
  width: 36px;
}

.messageImg {
  height: 36px;
  width: 36px;
}

.loginText {
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: 5px;
  cursor: pointer;
  color: #fff;
}

.loginAva {
  margin-left: 24px;
  height: 36px;
  width: 36px;
  cursor: pointer;
}

.ava {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.changeLanguageBox {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.changeLan {
  height: 20px;
  line-height: 20px;
  margin-left: 25px;
  color: #1a7dff;
  padding: 0 3px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 3px;
  margin-top: 5px;
  width: 60px;
  display: flex;
  justify-content: center;
}

.layout :global(.ant-layout .ant-layout-header) {
  padding: 0 0 0 48px;
}

.changeLanguageBoxChange {
  background-color: #fff;
  color: #1a7dff;
}

.menuLists {
  display: flex;

}

.superLayout {
  width: 100% !important;
  color: #fff !important;
  background: #006FFF !important;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

.menuAll {
  width: auto !important;
}

.menuAll:hover .menuDetail {
  display: block;
}

/* .layout:hover .menuDetail {
  display: block;
} */
.menuDetailNoShow {
  display: none;
}

.menuDetail {
  position: absolute;
  background: #F6F8FA;
  box-shadow: inset 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  top: 64px;
  z-index: 88888;
  width: 100%;
  left: 0px;
}

.moreDetail {
  background: #F6F8FA;
  display: flex;
  line-height: normal !important;

}

.moreDetailNone {
  width: 50px;
}

.moreDetailLeft {
  /* width: 480px; */
  width: 25%;
  padding: 32px 120px 32px 120px;
  box-shadow: inset 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  margin-right: -10px;
}

.moreDetailRight {
  background: #fff;
  width: 75%;
  box-shadow: inset 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
}

.moreDetailLeftTitle {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 26px;
  min-width: 120px;
  word-wrap: normal;
}

.moreDetailLeftDetail {
  font-size: 14px;
  font-weight: 400;
  word-wrap: normal;
  min-width: 120px;
  color: #666666;
}

.moreRightGuge {
  /* width: 240px; */
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  padding-left: 2px;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.moreRightGugeBox {
  width: 240px;
  padding: 30px 12px;
}



.moreRightGugeItem {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding-left: 12px;
  line-height: 36px;
}

.moreRightGugeItem:hover {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFangSC-Regular,
    PingFang SC;
  font-weight: 400;
  color: #006FFF;
  line-height: 36px;
  background: #E6F4FF;
  border-radius: 2px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
}

.moreRightGugeItemChildrenName {
  white-space: nowrap;
}

.moreRightGugeItemRightIcon {
  display: none;
}

.moreRightGugeItem:hover .moreRightGugeItemRightIcon {
  display: block;
}