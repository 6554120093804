.page {
  background-color: rgb(242, 248, 255);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px 43px 0px 43px;
}

.itemBox {
  display: flex;
}

.itemBox span {
  margin-top: 5px;
  margin-right: 4px;
  color: red;
}

.formLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}

.formTitle {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 5px;
}

/* .phoneInputBox {
  position: relative;
}
.phoneBox span {
  position: absolute;
} */

.phonexing {
  position: absolute;
  top: 335px;
  color: red;
}

.login {
  font-size: 14px;
  cursor: pointer;
}

.register {
  background-color: #006fff;
  text-align: center;
  padding: 5px 0;
  color: #fff;
  letter-spacing: 2px;
  cursor: pointer;
}

.uesereules {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.site-layout-content {
  width: 1200px;
  min-height: 280px;
  padding: 24px 45px;
  margin: 0 auto;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.backgroundBox {
  width: 100%;
  /* height: 520px; */
  background-color: white;
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
}

.pingtainame {
  z-index: 99999999;
  top: 124px;
  left: 202px;
  position: absolute;
}

.detail {
  margin-top: 46px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  width: 571px;
}

.pingtainame img {
  width: 471px;
  height: 50px;
}

.footItem {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 10px;
  margin-bottom: 25px;
}

.footerImg {
  height: 50px;
}

.layout {

  min-height: 100vh !important;
}