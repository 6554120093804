.site-layout-content {
  min-height: 280px;
  padding: 32px 60px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.backgroundBox {
  width: 100%;
  /* height: 520px; */
  background-color: white;
  position: relative;
}
.background {
  height: 100%;
  width: 100%;
}
.pingtainame {
  z-index: 99999999;
  top: 124px;
  left: 202px;
  position: absolute;
}
.detail {
  margin-top: 46px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  width: 571px;
}
.pingtainame img {
  width: 471px;
  height: 50px;
}
.footItem {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 10px;
  margin-bottom: 25px;
}
.page {
  /* background-color: rgb(251, 251, 251); */
}
.content {
  width: 108%;
  margin-left: -45px;
  display: flex;
}
.contentLeft {
  width: 225px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border-right: 1px solid #e9e9e9;
}
.contentLeftFirst {
  cursor: pointer;
  padding: 10px 0 10px 25px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.65);
}
.contentLeftFirstActive {
  cursor: pointer;
  padding: 10px 0 10px 25px;
  margin-bottom: 15px;
  /* background-color: #006fff; */
  color: #1894ff;
  background-color: rgb(229, 240, 255);
}
.firstContent {
  width: 100%;
  padding: 0 44px;
}
.smalltitle {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 15px;
}
