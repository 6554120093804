.site-layout-content {
  min-height: 280px;
  padding: 32px 60px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.backgroundBox {
  min-width: 1200px;
  /* height: 520px; */
  background-color: white;
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
  position: relative;
}

.pingtainame {
  z-index: 99999999;
  top: 124px;
  left: 202px;
  position: absolute;
}

.detail {
  margin-top: 46px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  width: 571px;
}

.pingtainame img {
  width: 471px;
  height: 50px;
}

.footItem {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 10px;
  margin-bottom: 25px;
}

.page {
  background-color: rgb(251, 251, 251);
}

.backgroundTitle {
  width: 700px;

  color: #fff !important;
}

.backgroundDes {
  width: 600px;

  color: #fff !important;
}

.titleBox {
  position: absolute;
  top: 100px;
  left: 450px;
  z-index: 12345;
}