.text {
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  line-height: 20px;
}
.feedback {
  cursor: pointer;
  color: #006fff;
}
.textHeight {
  display: flex;
  align-items: center;
}

.textHeight > div {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
.sucOperate {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1890ff;
}
.failOperate {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
}

.modalDiv :global(.ant-modal-content .ant-modal-header) {
  background: #006fff;
}
.modalDiv :global(.ant-modal-content .ant-modal-header .ant-modal-title) {
  color: #ffffff;
}
.modalDiv
  :global(.ant-modal-content .ant-modal-close .ant-modal-close-x .anticon) {
  font-size: 14px;
}
.modalDiv :global(.ant-modal-content .ant-modal-close .ant-modal-close-x) {
  color: #ffffff !important;
}
.modalDiv :global(.ant-modal-content .ant-modal-body) {
  padding: 23px 48px 33px;
}
.modalDiv :global(.ant-modal-content .ant-modal-footer) {
  display: flex;
  justify-content: center;
}

.modalDiv
  :global(.ant-modal-content
    .ant-modal-body
    .ant-form
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-input) {
  height: 88px;
}
.modalDiv :global(.ant-modal-content .ant-modal-footer .ant-btn) {
  padding: 4px 16px;
}
.modalDiv :global(.ant-modal-content .ant-modal-footer .ant-btn-primary) {
  margin-left: 16px !important;
  background-color: #006fff;
}
.modalDiv
  :global(.ant-modal
    .ant-modal-content
    .ant-modal-body
    .ant-form-horizontal
    .ant-form-item
    .ant-form-item-label
    > label::after) {
  margin-right: 15px;
}
.modalDiv
  :global(.ant-modal
    .ant-modal-content
    .ant-modal-body
    .ant-form-horizontal
    .ant-form-item
    .ant-form-item-label) {
  text-align: left;
  color: rgba(0, 0, 0, 0.45);
  flex: 0 0 22%;
  max-width: 22%;
}

.tableContent
  :global(.ant-table-wrapper
    .ant-spin-nested-loading
    .ant-spin-container
    .ant-table
    .ant-table-container
    .ant-table-content
    .ant-table-thead
    .ant-table-cell) {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
