.page {
  /* padding-bottom: 40px; */
}
.content {
  display: flex;
  justify-content: space-between;
}
.contentLeft {
  width: 944px;
  margin-right: 24px;
  position: relative;
}

.contentLeftHeader {
  display: flex;
  background-color: rgb(0, 111, 255, 0.1);
  padding: 13px 24px;
  font-size: 18px;
  color: #454545;
  justify-content: space-between;
  align-items: center;
}
.contentRightHeader {
  background-color: rgb(0, 111, 255, 0.1);
  font-size: 18px;
  padding: 13px 24px;
  color: #454545;
}
.contentRight {
  width: 504px;
  position: relative;
}
.leftUrlBox {
  width: 708px;
  height: 472px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f1;
}
.leftContent img {
  width: 100%;
  height: 472px;

  /* border: 1px solid rgba(0, 0, 0, 0.15); */
}
.rightContent {
  display: flex;
  flex-direction: column;
  font-size: 19px;
  border: 1px solid #f0f0f1;
  height: 472px;
}
.rightContentTop {
  margin: 21px 25px;
}
.rulesType {
  margin-top: 23px;
}
.name {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}
.thicknessNamePhoto {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  width: 60px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden;    /* 隐藏溢出的部分 */
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  display: inline-block;
  vertical-align: bottom;
}
.value {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}
.date {
  margin: 21px 0;
}
.rightContentBottom {
  border-top: 1px solid #d9d9d9;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0 24px 0 24px;
  padding-top: 17px;
}
.rightContentBottomTop {
  display: flex;
}
.rightContentBottomTopcolumn {
  display: flex;
  flex-direction: column;
}
.CartilageThickness {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 340px;
}
.rightboneJointsContentBottomTop {
  display: flex;
  flex-direction: column;
}
.skills {
  margin-left: 91px;
}
.valueNumber {
  color: #006fff;
  font-size: 14px;
}
.valueNumberCloumn {
  color: #006fff;
  font-size: 14px;
  margin-left: 10px;
}
.valueNumberBone {
  margin-left: 20px;
  color: #006fff;
  font-size: 14px;
}
.thicknessName {
  margin-left: 50px;
  color: #006fff;
  font-size: 14px;
  display: inline-block;
}
.rightContentBottomBottom {
  display: flex;
  align-items: center;
  height: 100%;
}

.rightContentBottomBottomImg img {
  height: 100%;
  width: 100%;
}
.buttonArea {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.buttonColor {
  background-color: #006fff;
  color: #fff;
  margin-bottom: 32px;
  font-size: 16px;
  padding: 0 28px;
  cursor: pointer;
}
.buttonColorNo {
  background-color: rgba(0, 111, 255, 0.5);
  color: #fff;
  margin-bottom: 32px;
  padding-bottom: 4px !important;
  padding-top: 1px !important;
  font-size: 16px;
  padding: 0 28px;
  cursor: pointer;
  border: none;
}
.begin {
  background-color: #006fff;
  color: #fff;
  margin-bottom: 32px;
  font-size: 16px;
  padding: 8px 28px;
  cursor: pointer;
}
.response {
  width: 100%;

  margin-top: 32px;
}
.responseName {
  padding: 16px 0 16px 24px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #454545;
  background-color: rgb(0, 111, 255, 0.1);
}
.responseContent {
  padding: 24px 48px;
  border: 1px solid #f0f0f1;
  display: flex;
  width: 100%;
}
.responseContentName {
  margin-right: 29px;
  min-width: 120px;
}
.responseContentValue {
  width: 100%;
}
.TextArea {
  width: 100%;
  margin-top: 32px;
}
.responseButton {
  border: 1px solid #f0f0f1;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 0;
}
.cancle {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 16px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
}
.submit {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 16px;
  background-color: #006fff;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}
.mask1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  z-index: 111;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mask1 :global(.ant-spin .ant-spin-dot .ant-spin-dot-item) {
  background-color: #fff;
}
.addNew {
  display: flex;
  align-items: center;
}
.addNewBtn {
  font-size: 14px;
}
.showmoreContent {
  height: 472px;
  border: 1px solid #d9d9d9;
  border-top: none;
  padding: 16px 24px 0 24px;
}
.PlusOutlined {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dragger {
  height: 60px !important;
}
/* .InputNumber {
  margin-left: 25px;
} */
.boneJointsTop {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.boneJointsLabel {
  display: flex;

  flex-direction: column;
}

.boneJointsValue {
  display: flex;
  margin-left: 25px;
  flex-direction: column;
}
.boneName {
  height: 45px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
}

.boneJointsvalueName {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  height: 45px;
  line-height: 45px;
}
.boneJointsInputBox {
  height: 45px;
  line-height: 45px;
  width: 180px;
}
.draggerPic {
  display: flex;
  flex-direction: column;
}
.filesListItem {
  color: "#666";
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.filesListItem:hover {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}
.twoImgsBox {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.twoImgs {
  width: 11px;
}
.contentLeftHeaderTitle span {
  margin-left: 15px;
  color: #006fff;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.imgReUpload {
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999999999;
  height: 60px;
  left: 0px;
  top: 412px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}
.imgReUpload span {
  cursor: pointer;
}
.leftContent {
  position: relative;
}
.space {
  margin-left: 30px;
}
