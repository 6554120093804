.ava {
  display: flex;
  align-items: flex-end;
}
.avaBox {
  width: 120px;
  height: 120px;
}
.avaBox img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.listLabel {
  display: flex;

  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
}
.listLabelLeft {
  margin-top: 34px;
  padding: 0 0 20px 12px;
  display: flex;
  color: #000000 65%;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.listLabelRight {
  display: flex;
  margin-top: 34px;
  padding: 0 12px 20px 0;
}
.label {
  width: 60px;
  margin-right: 36px;
}
.ok {
  cursor: pointer;
  margin-left: 12px;
  color: #52c41a;
}
.cancle {
  cursor: pointer;
  color: #d9d9d9;
}
.change {
  cursor: pointer;
  color: #006fff;
}
.page {
  padding-bottom: 40px;
}
