.items {
  display: flex;
  margin-bottom: 50px;
  cursor: pointer;
  background-color: #fff;
}

.items:hover {
  background-color: #f2f8ff;
}

.imgBox {
  min-width: 350px;
  height: 200px;
  overflow: hidden;
}

.imgBox img {
  height: 100%;
  width: 100%;
}

.Content {
  min-width: 760px;
  width: 100%;
  border: 1px solid #f0f0f1;
  border-left: none;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contentTitle {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.contentEn {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #006fff;
}

.contentDes {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.buttom {
  justify-content: center;
  text-align: center;
  display: flex;
  padding-bottom: 100px;
}

.moreBtn {
  /* width: 180px; */
  background-color: #006fff;
  border-radius: 30px;
  color: #fff;
  padding: 15px 30px;
  letter-spacing: 1px;
}

.projectItemImg:hover {
  scale: 1.2;

  transition: all .5s ease;
}

.projectItemImg {
  scale: 1;
  transition: all .5s ease;
}