.site-layout-content {
  width: 1200px;
  min-height: 280px;
  padding: 24px 45px;
  margin: 0 auto;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.backgroundBox {
  width: 100%;
  /* height: 520px; */
  background-color: white;
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
}

.pingtainame {
  z-index: 99999999;
  top: 124px;
  left: 202px;
  position: absolute;
}

.detail {
  margin-top: 46px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  width: 571px;
}

.pingtainame img {
  width: 471px;
  height: 50px;
}

.footItem {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 10px;
  margin-bottom: 25px;
}

.footerImg {
  height: 50px;
}

.layout {

  min-height: 100vh !important;
  position: relative;
}

.footerBotUser {
  color: #000;
}

.footerTop {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.footerTopCenter {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.footer1 {
  cursor: pointer;
}

.footerBot {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bfbfbf;
  font-size: 18px;
}

.routePagesHtml {
  height: calc(100vh - 64px);
  overflow-y: auto;
}